html,
body,
#root {
  height: 100%;
}
.App {
  height: 100%;
  overflow: hidden;
}
body {
  font-family: 'Open Sans', 'Noto Sans JP', sans-serif;
}

.chart {
  display: grid;
  grid-template-columns: 25% 1fr;
  grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
  align-items: stretch;
  width: 100%;
  height: 100%;
  color: black;
  text-align: left;
}
.chart {
  margin-right: 0.4em;
  margin-bottom: 0.2em;
  counter-reset: choice-item-counter;
}
.answer-chart {
  position: absolute;
  top: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
  align-items: stretch;
  width: 100%;
  height: 100%;
  color: black;
  text-align: left;
}

.answer.is-true {
  border: solid 10px #f00;
}
.answer.is-anim-true {
  animation: blink-animation 1s steps(2, start) infinite;
  -webkit-animation: blink-animation 1s steps(2, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}  

.chart .label {
  line-height: 1;
  border-right: solid 4px #aaa;
  display: block;
  align-items: center;
  padding-left: 30px;
  padding-right: 26px;
}
.chart .label.variant-figure {
  align-items: stretch;
  height: initial;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  counter-increment: choice-item-counter;
  justify-content: flex-end;
}
.chart .label.variant-figure:nth-child(1) .figure:before {
  background-color: #4285f4;
}
.chart .label.variant-figure:nth-child(3) .figure:before {
  background-color: #ea4335;
}
.chart .label.variant-figure:nth-child(5) .figure:before {
  background-color: #fbbc04;
}
.chart .label.variant-figure:nth-child(7) .figure:before {
  background-color: #34a853;
}
.chart .value {
  text-align: right;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.6em;
  font-weight: bold;
  line-height: 1;
  padding: 40px 0;
}
.chart .value .bar {
  box-sizing: border-box;
  padding-right: 10px;
  margin-right: 10px;
  transition: width 300ms 0s ease;
  overflow: hidden;
  min-height: 1em;
  height: 100%;
  max-height: 160px;
}
.chart .value .ratio {
  right: -60px;
  text-align: left;
  display: block;
}

.figure {
  width: 100%;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: solid 1px white;
  position: relative;
}
.figure:before {
  content: counter(choice-item-counter);
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  font-size: 1.5rem;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.autolabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: normal;
}
